import React, { useEffect, useState } from 'react';
import DollarSign from '../../images/dollarsign.svg';
import './salaryrange.css';

const SalaryLimit = ({ value, onChange, type }) => (
  <div className="border overflow-hidden relative flex items-center rounded" style={{ height: 55, width: '49%', borderColor: '#c4cad3' }}>
    <div className="h-full flex items-center justify-center" style={{ height: 55, width: 55, backgroundColor: '#e8ecf2' }}>
      <img src={DollarSign} style={{ height: 22, margin: 0 }} />
    </div>
    <input
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className="placeholder-primary font-medium flex-1 px-md w-1/2 h-full text-base"
      type="number"
      placeholder={type}
    />
  </div>
);

export default function SalaryRange({ hideTitle, update, value, isCandidate }) {
  const [salary, setSalary] = useState(value ? value : [0, 0]);

  useEffect(() => {
    update(salary);
  }, [salary]);

  return (
    <>
      {!hideTitle && (
        <div className="text-darkblue w-full font-medium " style={{ fontSize: 16, marginTop: 20, fontWeight: 400 }}>
          {!isCandidate ? 'Salary Range' : 'Minimum Salary Requirement'}
        </div>
      )}

      {!hideTitle && (
        <div className="mt-md flex justify-between">
          <SalaryLimit type='Min' value={`${salary[0]}`} onChange={(value) => setSalary([value, salary[1]])} />
          {!isCandidate && <SalaryLimit type='Max' value={`${salary[1]}`} onChange={(value) => setSalary([salary[0], value])} />}
        </div>
      )}
    </>
  );
}
