import { gql } from 'apollo-boost';

const CREATE_CANDIDATE = gql`
  mutation CreateCandidate(
    $id: String
    $name: String
    $email: String
    $phone: String
    $resumeURL: String
    $resumeFileName: String
    $referralName: String
    $recruiterID: String
    $linkedin: String
    $facebook: String
    $instagram: String
    $twitter: String
    $bio: String
    $location: jsonb
    $experience: Int
    $employment: jsonb
    $education: jsonb
    $attributes: jsonb
    $certifications: jsonb
    $preferredSkills: jsonb
    $salaryMin: Int
    $salaryMax: Int
    $locationPreferences: jsonb
    $interestedCity: jsonb
    $approved: Boolean
    $adminID: String
    $invite_code: String
    $invite_deeplink: String
    $invite_code_used_on: String
    $created_at: timestamptz
    $is_enriched: Boolean
  ) {
    insert_candidate(
      objects: {
        id: $id
        name: $name
        email: $email
        phone: $phone
        resumeURL: $resumeURL
        resumeFileName: $resumeFileName
        referralName: $referralName
        recruiter_id: $recruiterID
        linkedin: $linkedin
        facebook: $facebook
        instagram: $instagram
        twitter: $twitter
        bio: $bio
        location: $location
        experience: $experience
        employment: $employment
        education: $education
        attributes: $attributes
        certifications: $certifications
        preferredSkills: $preferredSkills
        salaryMax: $salaryMax
        salaryMin: $salaryMin
        locationPreferences: $locationPreferences
        interestedCity: $interestedCity
        approved: $approved
        adminID: $adminID
        invite_code: $invite_code
        invite_deeplink: $invite_deeplink
        invite_code_used_on: $invite_code_used_on
        created_at: $created_at
        is_enriched: $is_enriched
      }
    ) {
      returning {
        id
        name
      }
    }
  }
`;

export { CREATE_CANDIDATE };
