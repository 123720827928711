import React from 'react'

export default function FieldTitle({title, optional}) {
    return (
        <div>
        {title && 
                <div className='text-darkblue capitalize w-full font-medium flex justify-between' style={{ fontSize: 18, marginTop: 20, fontWeight: 400 }}>
                {title}
                {optional &&
                    <span className='uppercase text-xs text-darkgray'>
                        Optional
                    </span>}
            </div>}
        </div>)
}
