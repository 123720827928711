import React from 'react';
import { TextInput } from './';

export default function CandidateExperience({ value, update, placeholder }) {
  return (
    <div className="mt-lg w-full">
      <TextInput placeholder={placeholder} value={value} update={update} />
    </div>
  );
}

// OLD SLIDER
/*
return (
  <div className="mt-lg w-full">
    <Slider
      value={value}
      max={30}
      min={0}
      valueLabelDisplay="on"
      onChange={(_, value) => update(value)}
    />
  </div>
);
*/
