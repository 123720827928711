import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import React, { useEffect, useRef, useState } from 'react';
import CaretRight from '../../images/caret-right-solid.svg';
import CheckIcon from '../../images/check-white.svg';
import SelectDropdown from './SelectDropdown';
import FieldTitle from './FieldTitle';

const ALL_SKILLS = gql`
  {
    skill(order_by: [{ category: asc }, { name: asc }]) {
      category
      name
    }
  }
`;

const SEARCH_SKILLS = gql`
  query MyQuery($skill: String!) {
    cp_skills(where: {skill: {_ilike: $skill}}) {
      skill
      id
    }
  }
`;

function IndustryAttributesDropdown({ searchValue, industry, attributes, selectedState }) {
  const [open, toggleDropdown] = useState(false);
  const attributesContainerRef = useRef();
  const [attributesContainerHeight, setAttributesContainerHeight] = useState();
  const [selectedAttributes, setSelectedAttributes] = selectedState;
  /*
  useEffect(() => {
    if (attributesContainerRef.current) {
      const { height } = getComputedStyle(attributesContainerRef.current);
      setAttributesContainerHeight(parseInt(height.split('px')[0]));
    }
  }, [searchValue]);

  const filteredAttributes = attributes.filter((attribute) => {
    if (searchValue) {
      return attribute.toLowerCase().includes(searchValue.toLowerCase());
    } else {
      return true;
    }
  });
  */
  return filteredAttributes.length > 0 ? (
    <div
      onClick={(e) => e.stopPropagation()}
      className={`${open ? 'border-b' : ''} relative text-darkblue text-sm overflow-hidden`}
      style={{ height: open ? attributesContainerHeight + 39 : 39 }}
    >
      <div onClick={() => toggleDropdown(!open)} className="font-medium border-b p-sm flex items-center cursor-pointer hover:bg-lightgray">
        <img
          alt=""
          src={CaretRight}
          style={{ transition: 'transform 0.2s ease', transform: `rotate(${open ? '90deg' : '0'})`, height: 15, width: 15, marginBottom: 0, marginRight: 10 }}
        />
        {industry}
      </div>
      <div ref={attributesContainerRef} className="absolute w-full bg-white overflow-auto border-b" style={{ maxHeight: 311 }}>
        {filteredAttributes?.map((attribute, index) => {
          const selected = selectedAttributes.includes(attribute);
          return (
            <div
              key={index}
              onClick={() => {
                if (selected) {
                  setSelectedAttributes(selectedAttributes.filter((att) => att !== attribute));
                } else {
                  setSelectedAttributes([...selectedAttributes, attribute]);
                }
              }}
              className={`cursor-pointer ${selected ? 'bg-green text-white' : 'hover:bg-lightgray'} flex justify-between items-center`}
              style={{ paddingLeft: 33, paddingTop: 5, paddingBottom: 5 }}
            >
              {attribute}
              {selected && <img alt="" src={CheckIcon} style={{ height: 12, width: 12, marginBottom: 0, marginRight: 10 }} />}
            </div>
          );
        })}
      </div>
    </div>
  ) : null;
}

export default function AttributesDropdown({ value: selectedAttributes, update: setSelectedAttributes, error, className, style, containerStyle, onEnter = () => { } }) {
  const placeholder = 'Search Attributes';
  const [inputValue, setInputValue] = useState('');
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [searchSkills, { loading: skillsLoading, data: skillsData, error: skillsError }] = useLazyQuery(SEARCH_SKILLS);
  const { data } = useQuery(ALL_SKILLS);
  const [allAttributes, setAllAttributes] = useState([]);

  function hideDropdown() {
    setDropdownVisible(false);
  }

  useEffect(() => {
    if (dropdownVisible) {
      window.addEventListener('click', hideDropdown);
    } else {
      setInputValue('');
      return window.removeEventListener('click', hideDropdown);
    }
  }, [dropdownVisible]);

  useEffect(() => {
    if (inputValue?.length > 2) {      
      searchSkills({
        variables: { skill: `%${inputValue}%` },
      });
    }
  }, [inputValue])
  /*
  useEffect(() => {
    if (data) {
      console.log({ data })
      setAllAttributes(
        data.skill.reduce((industriesList, attribute) => {
          const category = industriesList[attribute.category];
          if (category) {
            return { ...industriesList, [attribute.category]: [...category, attribute.name] };
          } else {
            return { ...industriesList, [attribute.category]: [attribute.name] };
          }
        }, {}),
      );
    }
  }, [data]);
  */
  const excludedPlaceholder = ['Search Attributes'];
  return (
    <>
      <div className={`relative`} style={containerStyle}>
        {placeholder && !excludedPlaceholder.includes(placeholder) && <FieldTitle title={placeholder} />}
        <input
          onClick={(e) => e.stopPropagation()}
          autoComplete="new-password"
          value={inputValue}
          onFocus={() => setDropdownVisible(true)}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder={placeholder}
          className={className}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && onEnter) {
              onEnter();
            }
          }}
          style={{
            ...style,
            zIndex: dropdownVisible ? 501 : 499,
            borderColor: error ? '#E31C25' : '#c4cad3',
            // marginTop: 20
          }}
        />
        {dropdownVisible && (
          <div
            className="absolute bg-white w-full border-r border-l overflow-auto shadow"
            style={{
              top: 'calc(100% - 10px)',
              zIndex: 500,
              paddingTop: 10,
              maxHeight: 350,
            }}
          >
            <div onClick={(e) => e.stopPropagation()}>
            {
              skillsLoading ? <span>searching....</span> :
              inputValue?.length > 2 && (skillsData?.cp_skills || [])?.map(elem => {
                const selected = selectedAttributes.includes(elem?.skill);
                return (
                  <div 
                    key={elem?.id}
                    className={`cursor-pointer ${selected ? 'bg-green text-white' : 'hover:bg-lightgray'} flex justify-between items-center`}
                    style={{ paddingLeft: 33, paddingTop: 5, paddingBottom: 5 }}
                    onClick={() => {
                      if (selected) {
                        setSelectedAttributes(selectedAttributes.filter((att) => att !== elem?.skill));
                      } else {
                        setSelectedAttributes([...selectedAttributes, elem?.skill]);
                      }
                    }}
                    >
                      {elem?.skill}
                      {selected && <img alt="" src={CheckIcon} style={{ height: 12, width: 12, marginBottom: 0, marginRight: 10 }} />}
                  </div>
                )
              }
              )
            }
            {/* {skillsData?.cp_skills?.map((skill, i) => (
              <li>{skill}</li>
             
            ))} */}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

const industries = [
  'Gym Membership',
  'On-Site',
  'Hybrid',
  'Bonus Eligible',
  'Stock Options',
  'Meal Stipend',
  '401K',
  'Medical Benefits (Fully Covered)',
  'Medical Benefits (Split)',
  'Unlimited PTO',
  'Corporate Discount Program',
  'Stock Purchase Plan',
  'Vaccination Required',
];

export function CompensationDropdown({ update, value, error }) {
  const [selectedIndustries, setSelectedIndustries] = useState(value ? value : []);

  useEffect(() => {
    update(selectedIndustries);
  }, [selectedIndustries]);

  return (
    <>
      <SelectDropdown
        error={error}
        update={(val) => val && setSelectedIndustries([...selectedIndustries, val])}
        placeholder="Compensation"
        list={industries.filter((industry) => !selectedIndustries.includes(industry))}
      />
    </>
  );
}
