import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import SEO from '../../components/common/seo';
import NewLandingBackgroundImage from '../../images/cp_bg.png';
import LandingBackgroundImage from '../../images/landing-background.png';
import Logo from '../../images/landing-logo.svg';
import LoginForm from '../../forms/gateway/LoginForm';
import NewAccountForm from '../../forms/gateway/NewAccountForm';
import NewCompanyAccountForm from '../../forms/gateway/NewCompanyAccountForm';
import styled from 'styled-components';
import ResetPasswordForm from '../../forms/gateway/ResetPasswordForm';
const secondSlides = ['/new-account', '/reset-password', '/cpcjadmin320'];
import { ToastContainer, toast } from 'material-react-toastify';

const Gateway = ({ path }) => {
  const [slide, setSlide] = useState(secondSlides.includes(path));
  const [slideType, setSlideType] = useState(secondSlides.includes(path) && path);

  useEffect(() => {
    setSlide(secondSlides.includes(path));
    if (secondSlides.includes(path)) {
      setSlideType(path);
    }
  }, [path]);
  const pathName = window.location.pathname

  const allowedNewForm = ['/login', '/reset-password'];
  const isDesiredUrls = allowedNewForm.includes(pathName);
  // const isDesiredUrls = false;
  const newGetWaySubContainerStyle = {
    width: '100%',
    maxWidth: 520,
    background: 'white',
    minHeight: 700,
    borderRadius: '32px',
    marginTop: '3px',
    boxShadow: '0 1px 6px 0 rgba(13,12,84,.02), 0 6px 22px 0 rgba(13,12,84,.04)',
  }

  const getWaySubContainerStyle = {
    width: '100%', maxWidth: 400, height: 432
  }
  return (
    <div
      className="w-full overflow-auto relative h-full flex flex-col font-main text-white justify-center items-center"
      style={{
        minHeight: '100vh',
        minWidth: '100vw',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        fontSize: 16,
        backgroundImage: `url(${!isDesiredUrls ? LandingBackgroundImage : NewLandingBackgroundImage}`,
      }}
    >
      {!isDesiredUrls && <img style={{ width: 200, margin: 0 }} src={Logo} />}
      <div className="relative"
        style={!isDesiredUrls ? getWaySubContainerStyle : newGetWaySubContainerStyle}>
        <div
          className="absolute top-0 bottom-0 flex"
          style={{
            width: '200%',
            transition: 'transform 0.3s ease-in-out',
            transform: `translateX(${!slide ? `0px` : `-50%`})`,
          }}
        >
          <div className="flex flex-col items-center w-1/2 mr-0 sm:mr-md relative">
            <LoginForm isActive={!slide} activateSlider={() => setSlide(true)} setSlideType={setSlideType} />
            <div
              className="absolute inset-0"
              style={{
                transition: 'display 0.3s',
                display: slide ? 'block' : 'none',
              }}
            />
          </div>
          <br />
          <div style={{
            transition: 'opacity 0.3s ease-in-out',
            boxSizing: 'border-box',
            padding: '62px 13px 0px 32px'
          }} className=" flex flex-col items-center xs:mr-0 w-1/2 sm:mr-lg relative">
            {/* this is for signing up new recruiter */}
            {/* {slideType === '/new-account' && <NewAccountForm isActive={slide} activateSlider={() => setSlide(false)} />} */}
            {/* this is for signing up new company */}
            {slideType === '/cpcjadmin320' && <NewCompanyAccountForm isActive={slide} activateSlider={() => setSlide(false)} />}
            {slideType !== '/new-account' &&
              // this doesnt seem to work
              slideType !== '/new-company-account' && <ResetPasswordForm isActive={slide} activateSlider={() => setSlide(false)} />}
            <div className="absolute inset-0" style={{ display: slide ? 'none' : 'block' }} />
          </div>
        </div>
      </div>
      <ToastContainer position="bottom-center" autoClose={3000} hideProgressBar newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </div>
  );
};

export default Gateway;
