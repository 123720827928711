import { Field, Form as FormikForm, Formik } from 'formik';
import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import * as Yup from 'yup';
import ErrorMessage from './fields/ErrorMessage';

export const Form = forwardRef((props, ref) => {
  const fields = props.fields;
  const [initialValues, setInitialValues] = useState();
  const [validationSchema, setValidationSchema] = useState();
  const [showErrors, setShowErrors] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    init();
  }, []);

  const init = (changes) => {
    setLoading(true);
    const _fields = changes || fields;
    setInitialValues(Object.fromEntries(Object.entries(_fields).map(([label, { value }]) => [label, value])));
    const validationEntries = Object.fromEntries(Object.entries(_fields).map(([label, { validation }]) => [label, validation]));
    setValidationSchema(Yup.object().shape({ ...validationEntries }));

    const gatewayPaths = ['/new-account', '/new-company-account', '/login', '/reset-password'];
    if (typeof window !== 'undefined') {
      if (gatewayPaths.includes(window.location.pathname)) {
        setShowErrors(false);
      }
    }
    setLoading(false);
  };

  // The component instance will be extended
  // with whatever you return from the callback passed
  // as the second argument
  useImperativeHandle(ref, () => ({

    render(changes) {
      const updates = { ...fields };
      for (let index = 0; index < Object.keys(changes).length; index++) {
        const element = Object.keys(changes)[index];
        if (element in updates && updates[element] && 'value' in updates[element]) {
          updates[element].value = changes[element]
        }
      }
      init(updates);
    }
  }));

  return !loading && initialValues && validationSchema ? (
    <Formik initialValues={initialValues} onSubmit={props.onSubmit} enableReinitialize={true} validationSchema={validationSchema}>
      {({ values, setFieldValue }) => {
        return (
          <FormikForm className={`w-full`}>
            {Object.entries(values).map(([label], index) => (
              <Field key={label} name={label}>
                {({ field: { value }, form: { errors, touched } }) => {
                  const { placeholder, fieldName, component, type, title, optional, customProps = null } = fields[label] ? fields[label] : {};
                  const FieldComponent = component;
                  const error = (touched[label] && errors[label]) || (props.customErrors && props.customErrors[label]);

                  return (
                    <React.Fragment>
                      {title && (
                        <div className="text-darkblue w-full flex items-center font-medium flex justify-between" style={{ fontSize: 18, marginTop: 40, fontWeight: 400 }}>
                          <div>
                            {title}
                            {optional && <span className="uppercase text-xs text-darkgray">Optional</span>}
                          </div>
                          <div className="flex-1 border-b ml-md" />
                        </div>
                      )}
                      {
                        fieldName && (
                          <div className="text-red text-left font-semibold py-2" style={{ fontSize: 18, alignSelf: 'flex-start' }}>
                            {fieldName}
                          </div>
                        )
                      }
                      <FieldComponent
                        key={index}
                        optional={optional}
                        type={type}
                        value={value}
                        update={(val) => {
                          setFieldValue(label, val);

                          if (props.customErrors && props.customErrors[label] && props.setCustomErrors) {
                            props.setCustomErrors({ ...props.customErrors, [label]: null });
                          }
                        }}
                        error={error}
                        setFieldValue={setFieldValue}
                        placeholder={placeholder ? placeholder : label}
                        {
                        ...customProps
                        }
                      />
                      {showErrors && error && label !== 'employment' && label !== 'education' && <ErrorMessage error={error} />}
                    </React.Fragment>
                  );
                }}
              </Field>
            ))}
            {props.children}
          </FormikForm>
        );
      }}
    </Formik>
  ) : null;
});

export default Form;