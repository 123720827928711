import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useStripe } from '@stripe/react-stripe-js';
import { EXPRESS_SERVER_URL } from '../../config';
import { loadStripe } from '@stripe/stripe-js';
import Loader from "react-loader-spinner"
import { navigate } from '@reach/router';

function Loading() {
    return (
        <div
            style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        >
            <Loader type="TailSpin" color="red" height={50} width={50} />
        </div>
    )
}

function CpPayment() {
    const [loading, setLoading] = useState(false);
    const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PK);
    const userId = window.location.search.split('=')[1]
    const handleStripePay = async () => {
        setLoading(true);
        const { data } = await axios.post(`${EXPRESS_SERVER_URL}/payment/create-checkout-session`, {
            customerId: userId,
        });
        if(data?.status === 'fail'){
            navigate('/')
            return
        }
        try {
            const stripe = await stripePromise;
            const { error } = await stripe.redirectToCheckout({
                sessionId: data.sessionId
            });

            if (error) {
                console.error('Error redirecting to Checkout:', error);
            }
        } catch (error) {
            console.error('Error initializing Stripe:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        handleStripePay();
    }, [])

    return (
        <div>
            {
                loading ? <Loading /> :
                    <div>
                        {/* <button onClick={handleStripePay}>Click</button> */}
                    </div>
            }
        </div>
    )
}

export default CpPayment;