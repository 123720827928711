import React from "react";
import styled from "styled-components";

const Input = styled.input`
  width: 400px;
  ::placeholder {
    color: white;
    opacity: 1;
    text-transform: capitalize;
  }

  backdrop-filter: blur(10px);
  background-color: transparent !important;
  @media only screen and (max-width: 640px) {
    width: 100%;
  }
`;

export default function LandingInput({
  placeholder,
  update,
  value,
  type,
  error,
  fieldName
}) {
  return (
    <>
      {
        fieldName && (
          <div className="text-red text-left font-semibold py-2" style={{ fontSize: 18, alignSelf: 'flex-start' }}>
            {fieldName}
          </div>
        )
      }
      <Input
        value={value}
        placeholder={placeholder}
        onChange={e => update(e.target.value)}
        type={type && type}
        className={`p-md mb-md w-1/4 border sm:w-full ${error ? "border-red" : "border-white"
          } text-white font-main bg-transparent rounded`}
      />
      {error && (
        <div
          className="mb-md text-red text-center text-sm rounded px-md py-sm"
          style={{ backgroundColor: "rgba(255,255,255,0.7)" }}>
          {error}
        </div>
      )}
    </>
  );
}
