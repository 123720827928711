import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { EXPRESS_SERVER_URL } from '../../config';
import './PaymentSuccess.css'
import '../../styles.css'
import Loader from "react-loader-spinner"
import SuccessIcon from '../../components/common/SuccessIcon';

function Loading() {
    return (
        <div
            style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        >
            <Loader type="TailSpin" color="red" height={50} width={50} />
        </div>
    )
}

function PaymentSuccess() {
    const [paymentDetails, setPaymentDetails] = useState();
    const [loader, setLoader] = useState(false);
    const getPaymentDetails = async () => {
        setLoader(true);
        try {
            const { data: { data } } = await axios.get(`${EXPRESS_SERVER_URL}/order/success${window.location.search}`)
            const { data: { data: subscription } } = await axios.get(`${EXPRESS_SERVER_URL}/subscription?subscription_id=${data?.subscription}`)
            setPaymentDetails({ ...data, paymentId: subscription?.default_payment_method });
        } catch (error) {
            console.log(error)
        } finally {
            setLoader(false)
        }
    }

    useEffect(() => {
        getPaymentDetails();
    }, []);

    const closeTab = () => {
        window.opener = null;
        window.open("", "_self");
        window.close();
    };

    return (
        <div className="bg-gray-100 h-screen w-full">
            {
                loader ? <Loading /> : <div className="bg-gray-200 p-6 md:mx-auto mt-0 flex flex-col w-full md:max-w-2xl font-helvetica">
                    <div className='flex align-middle justify-center'>
                        <SuccessIcon />
                    </div>

                    <div className='mt-4'>
                        <h3
                            className="md:text-2xl text-base text-green font-semibold text-center"
                        >
                            Payment Done!
                        </h3>
                        <p className="text-gray-600 my-2 text-center">
                            Thank you for completing your secure online payment.
                        </p>
                        <p className='text-gray-600 text-center'>Have a great day!</p>
                        <div className="grid grid-rows-3 m-auto mt-7 text-gray-600">
                            <div className="flex flex-col justify-center gap-0 align-baseline md:grid md:grid-cols-2 md:align-middle">
                                <p className='align-middle md:text-left mb-1'>Email</p>
                                <p className="font-bold text-left md:text-right">{paymentDetails?.customer_details?.email}</p>
                            </div>
                            <div className="flex flex-col justify-center gap-0 align-baseline md:grid md:grid-cols-2 md:align-middle">
                                <p className='align-middle md:text-left mb-1'>Amount Paid</p>
                                <p className="font-bold text-left md:text-right">${paymentDetails?.amount_subtotal / 100}</p>
                            </div>
                            <div className="flex flex-col justify-center gap-0 align-baseline md:grid md:grid-cols-2 md:align-middle">
                                <p className="align-middle md:text-left mb-1">Transaction Id</p>
                                <p className="font-bold text-left md:text-right">{paymentDetails?.paymentId}</p>
                            </div>
                        </div>

                        <div className='flex justify-center align-middle'>
                            <a
                                style={{
                                    background: "#E31C25",
                                    color: "white",
                                    fontSize: "1rem",
                                    padding: "0.5rem 2rem",
                                    cursor: "pointer",
                                    borderRadius: '0.2rem'
                                }}
                                onClick={() => closeTab()}
                            >
                                Close
                            </a>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default PaymentSuccess