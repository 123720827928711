import React, { useEffect, useState, useRef } from 'react';
import closeIcon from '../../images/close-gray.svg';

/**
 * Validation popup for filter search
 * @param {Object} props Modal props
 * @param {Boolean} props.open View Modal
 * @param {String} props.validationError Validation Message
 * @param {Function} props.onChange On Change
 * 
 * @returns 
 */
export const ConfirmationModal = (props) => {
    const [fadeDelay, setFadeDelay] = useState(false);
    const { modalTitle = '' } = props || {};
    // Ref's
    const modalRef = useRef();

    useEffect(() => {
        if (props.open) {
            setFadeDelay(true);
        } else {
            setTimeout(() => {
                setFadeDelay(false);
            }, 300);
        }
    }, [props]);

    const handleChanges = (value) => {
        if (props.onChange) {
            props.onChange(value);
        }
    }

    const handleConfirmClick = (confirm) => {
        confirm();
        handleChanges(false)
    }

    return (
        <div
            className={`fixed inset-0 items-center justify-center`}
            onClick={() => handleChanges(false)}
            style={{
                transition: 'opacity 0.3s ease-in-out',
                opacity: props.open ? 1 : 0,
                zIndex: 3000000,
                display: fadeDelay ? 'flex' : 'none',
                backgroundColor: 'rgba(34, 46, 66, 0.9)',
            }}>
            <div ref={modalRef} onClick={(e) => e.stopPropagation()} className="bg-white px-xl py-md rounded shadow flex flex-col" style={{ minHeight: 140 }}>
            <div className={`flex justify-between align-middle text-center ${modalTitle && 'mb-4'}`}>
                <div className='font-medium text-lg'>{modalTitle ? modalTitle : null}</div>
                <img src={closeIcon} onClick={() => handleChanges(false)} style={{
                    width: 14,
                    height: 14,
                    marginBottom: 15,
                    cursor: 'pointer',
                }} />
                </div>
                <p>
                    {props.children}
                </p>
                <div className="flex flex-1 justify-center items-end" style={{ marginTop: 10, marginBottom: 10 }}>
                    {
                        props?.showCancel ? (
                            <button
                                className="rounded border mr-sm font-medium text-sm bg-lightgray text-darkgray"
                                style={{ width: '25%', height: 40, fontSize: 14 }}
                                onClick={() => handleChanges(false)}
                            >
                                Cancel
                            </button>
                        ) : null
                    }
                    <button
                        className="rounded bg-red ml-md font-medium  text-sm text-white"
                        style={{ width: '25%', height: 40, fontSize: 14 }}
                        // onClick={() => handleChanges(!!props?.confirm())}
                        onClick={() => handleConfirmClick(props?.confirm)}
                    >
                        OK
                    </button>

                </div>
            </div>
        </div>
    )
}

export default ConfirmationModal;