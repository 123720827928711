import { Editor } from '@tinymce/tinymce-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import './RichTextArea.css';
import FieldTitle from './FieldTitle';

function RichTextArea({ value, update, placeholder, error, height = 200 }) {
  const [rich, setRich] = useState(value || "");
  const editorRef = useRef(null);
  // useEffect(() => {
  //   setRich(value);
  // }, [value]);

  useEffect(() => {
    if (editorRef.current) {
      const editorInstance = editorRef.current.editor;
      if (editorInstance) {
        editorInstance.setContent(value)
      }
    } else if (!editorRef.current?.editor && !rich) {
      setRich(value);
    }
  }, [value])

  const handleEditorChange = useCallback(
    (newValue, editor) => {
      update(newValue);
    }, [update]
  )

  return (
    <div className='w-full'>
      {['bio'].includes(placeholder) && <FieldTitle title="Description" />}
    <div className={`${error ? 'editor-error' : ''} w-full`}>
      <Editor
        apiKey={process.env.GATSBY_TINYMCE_KEY}
        initialValue={rich}
        init={{
          paste_retain_style_properties: '',
          directionality: 'ltr',
          height,
        }}
        plugins="lists paste"
        toolbar="bold italic bullist numlist"
        // onEditorChange={update}
        onEditorChange={handleEditorChange}
        placeholder={placeholder}
        onInit={(editor) => {
          editorRef.current = editor;
        }}
      />
    </div>
    </div>
  );
}

export default React.memo(RichTextArea);