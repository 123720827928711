import { ApolloProvider, useSubscription } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { navigate } from 'gatsby';
import { request } from 'graphql-request';
import React, { useEffect, useRef, useState } from 'react';
import Loader from 'react-loader-spinner';
import SnackbarProvider from 'react-simple-snackbar';
import SEO from '../../components/common/seo';
import { EXPRESS_SERVER_URL, HTTP_HASURA_API_URL } from '../../config';
import { ConfirmModalContext, UserRoleContext } from '../../context';
import { ApolloContext } from '../../context/Apollo';
import ConfirmModal from '../../context/ConfirmModal';
import useApollo from '../../hooks/useApollo';
import useFirebase from '../../hooks/useFirebase';
import '../../styles.css';
import Gateway, { CreateAccount } from '../../views/gateway';
import './FilterStyles.css';
import Header from './header';
import './layout.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import CpPayment from '../../views/candidates/CpPayment';
import PaymentSuccess from '../../views/candidates/PaymentSuccess';
import firebase from 'firebase/app';
import { useQuery } from '@apollo/react-hooks';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';

const GET_RECRUITER = gql`
  query getRecruiter($id: String) {
    recruiter(where: { id: {_eq: $id}}) {
        id
        permissions
        company {
          is_agency
          id
        }
    }
  }
`;

export const GlobalFilterContext = React.createContext();

const HAS_JOB_OR_CANDIDATES_SUBSCRIPTION = gql`
  subscription jobsCandidatesCheck($id: String) {
    recruiter(where: { id: { _eq: $id } }) {
      jobs_aggregate {
        aggregate {
          count
        }
      }
      candidates_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

const CHECK_USER_TYPE = `
  query recruiterCheck($email: String) {
    recruiter(where: { email: { _eq: $email } }) {
      email
      jobs_aggregate {
        aggregate {
          count
        }
      }
      candidates_aggregate {
        aggregate {
          count
        }
      }
    }
    company(where: { email: { _eq: $email } }) {
      email
      id
      logoURL
      is_agency
    }
  }
`;


function PageLoading() {
  return (
    <div className="h-screen w-screen flex items-center justify-center">
      <Loader type="TailSpin" color="#E31C25" height={50} width={50} />
    </div>
  );
}

export const HasJobsOrCandidatesContext = React.createContext();

function AppContainer({ path, children, currentUser, profileModalState, apolloClient }) {
  const companyRoutes = [
    '/dashboard/find-candidate',
    '/dashboard/jobs',
    '/dashboard/completed-jobs',
    '/dashboard/requests-sent',
    '/dashboard/interested-candidates',
    '/checkout',
    '/saved-candidates',
    '/dashboard/reports',  
    '/dashboard/talent-finder',
    '/dashboard/talent-finder-linkedin',
    '/dashboard/talent-finder-csv',
    '/dashboard/placement-report',
    '/dashboard/transaction-history',
    '/favorite-candidates',
    '/jobs/new-job',
  ];
  const [hasJobsOrCandidates, setHasJobOrCandidates] = useState(false);
  const [fade, setFade] = useState(false);
  const scrollContainerRef = useRef();
  const [userType, setUserType] = useState();
  const [modal, setModal] = useState({ data: null, open: false });
  const [globalFilter, setGlobalFilter] = useState({});
  const [recruiterAgency, setRecruiterAgency] = useState(false);
  const [recruiterPermission, setRecruiterPermission] = useState()
  const [recruiterAgencyId, setRecruiterAgencyId] = useState('');
  const [agencyID, setAgencyID] = useState('');
  const [agencyDetails, setAgencyDetails] = useState({});
  const [isOnBoarded, setIsOnBoarded] = useState(true);
  const adminID = firebase.auth().currentUser ? firebase.auth().currentUser.uid : 'not-logged-in'
  const { data: jobsOrCandidatesData } = useSubscription(HAS_JOB_OR_CANDIDATES_SUBSCRIPTION, {
    variables: {
      id: currentUser ? currentUser.uid : 'no_user_logged_in',
    },
    shouldResubscribe: true,
  });

  const { data: recruiterData } = useQuery(GET_RECRUITER, {
    variables: {
      id: adminID,
    },
  });
  // TODO
  const getStripeOnboardingDetails = async (id) => {
    try {
      const { data } = await axios.get(`${EXPRESS_SERVER_URL}/onboarding-details/${id}`);
      if (data?.data?.length) {
        const { charges_enabled, details_submitted, payouts_enabled } = data?.data[0];
        if (charges_enabled === true && details_submitted === true && payouts_enabled === true) {
          setIsOnBoarded(true)
        } else {
          setIsOnBoarded(false);
        }
      } else {
        setIsOnBoarded(false);
      }
    } catch (error) {
    }
  }

  useEffect(() => {
    if (agencyID) {
      getStripeOnboardingDetails(agencyID)
    }
  }, [agencyID])

  useEffect(() => {
    if (recruiterData) {
      setRecruiterAgency(recruiterData?.recruiter?.[0]?.company?.is_agency);
      setRecruiterAgencyId(recruiterData?.recruiter?.[0]?.company?.id);
      setRecruiterPermission(recruiterData?.recruiter?.[0]?.permissions?.allow_candidate_export || false);
    }
  }, [recruiterData])

  const agencyRoutes = [
    '/dashboard/candidates',
    '/dashboard/jobs',
    '/dashboard/recruiters',
    '/dashboard/companies',
    '/companies/new-company',
    '/dashboard/reports',
    '/dashboard/placement-report',
    '/candidates/new-candidate',
  ];

  const adminRoutes = [
    '/dashboard/candidates',
    '/dashboard/jobs',
    '/dashboard/job-queue',
    '/dashboard/recruiters',
    '/dashboard/subscribers',
    '/dashboard/companies',
    '/dashboard/attributes',
    '/dashboard/new-attribute',
    '/dashboard/reports',
    '/subscribers/new-subscriber',
    '/companies/new-company',
    '/candidates/new-candidate',
    '/recruiters/new-recruiter',
    '/jobs/new-job',
    '/dashboard/recruiter-activity',
    '/dashboard/subscriber-activity',
    '/dashboard/subscriber-credit-report',
    '/dashboard/candidate-introduction-report',
    '/dashboard/placement-report',
    '/dashboard/agencies',
    '/dashboard/credit-transaction-history',
  ];

  useEffect(() => {
    if (jobsOrCandidatesData) {
      const { recruiter } = jobsOrCandidatesData;
      if (recruiter.length === 0 || recruiter[0].jobs_aggregate.aggregate.count !== 0 || recruiter[0].candidates_aggregate.aggregate.count !== 0) {
        setHasJobOrCandidates(true);
      }
    }
  }, [jobsOrCandidatesData]);

  useEffect(() => {
    setFade(true);
  }, []);

  useEffect(() => {
    if (userType && scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = 0;
    }
  }, [path]);

  useEffect(() => {
    if (currentUser) {
      request(HTTP_HASURA_API_URL, CHECK_USER_TYPE, {
        email: currentUser.email,
      })
        .then(({ recruiter, company }) => {
          if (recruiter.length > 0) {
            setUserType('recruiter');
          } else if (company.length > 0) {
            if (company[0].is_agency) {
              setAgencyID(company[0]?.id);
              setAgencyDetails(company[0]);
              setUserType('agency');
            } else {
              setUserType('company');
            }
          } else {
            setUserType('admin')
          }

          if ((company.length > 0 && !company.is_agency) || recruiter.length === 0) {
            setHasJobOrCandidates(true);
          }
          if (recruiter.length > 0) {
            if (recruiter[0].jobs_aggregate.aggregate.count !== 0 || recruiter[0].candidates_aggregate.aggregate.count !== 0) {
              setHasJobOrCandidates(true);
            }
          }
        })
        .catch(console.log);
    }
  }, [currentUser]);

  if (!userType) {
    return <PageLoading />;
  }

  if (
    userType === 'company' &&
    !companyRoutes.includes(path) &&
    ((path.split('/')[1] === 'jobs' && path.split('/')[2] === 'open-jobs') ||
      (path.split('/')[1] === 'jobs' && path.split('/')[2] === 'all-jobs') ||
      (path.split('/')[1] === 'jobs' && path.split('/')[2] === 'completed-jobs') ||
      (path.split('/')[1] === 'dashboard' && path.split('/')[2] === 'recruiter-activity') ||
      (path.split('/')[1] === 'dashboard' && path.split('/')[2] === 'subscriber-activity') ||
      (path.split('/')[1] === 'dashboard' && path.split('/')[2] === 'subscriber-credit-report') ||
      (path.split('/')[1] === 'dashboard' && path.split('/')[2] === 'candidate-introduction-report') ||
      (path.split('/')[1] === 'dashboard' && path.split('/')[2] === 'agencies') ||
      (path.split('/')[1] === 'dashboard' && path.split('/')[2] === 'placement-report') ||
      (path.split('/')[1] === 'dashboard' && path.split('/')[2] === 'transaction-history') ||
      (path.split('/')[1] === 'dashboard' && path.split('/')[2] === 'reports') ||
      (path.split('/')[1] === 'dashboard' && path.split('/')[2] === 'report') ||
      (path.split('/')[1] === 'dashboard' && path.split('/')[2] === 'credit-transaction-history') ||
      (path.split('/')[1] === 'dashboard' && path.split('/')[2] === 'recruiter-credit-activity') ||
      (path.split('/')[1] === 'dashboard' && path.split('/')[2] === 'recruiter-job-output'))
  ) {
    navigate('/dashboard/find-candidate');
    return <PageLoading />;
  }

  if (
    userType === 'recruiter' &&
    (path === '/saved-candidates' || path === '/favorite-candidates' || path === '/checkout' || path === '/dashboard/job-queue' || path === '/dashboard/jobs')
  ) {
    navigate('/dashboard/candidates-in-process');
    return <PageLoading />;
  }

  if (
    userType === 'agency' &&
    !agencyRoutes.includes(path) &&
    path.split('/').length === 3 &&
    ![
      'recruiters',
      'candidates',
      'companies',
      'recruiter-activity',
      'reports',
      'placement-report',
      'jobs',
    ].includes(path.split('/')[1])
  ) {
    navigate('/dashboard/candidates');
    return <PageLoading />;
  }

  if (
    userType === 'admin' &&
    !adminRoutes.includes(path) &&
    path.split('/').length === 3 &&
    ![
      'jobs',
      'job-queue',
      'recruiters',
      'candidates',
      'subscribers',
      'companies',
      'reports',
      'attributes',
      'agencies',
      'recruiter-activity',
      'subscriber-activity',
      'subscriber-credit-report',
      'candidate-introduction-report',
      'placement-report',
      'credit-transaction-history',
    ].includes(path.split('/')[1])
  ) {
    navigate('/dashboard/candidates');
    return <PageLoading />;
  }

  let seoTitle = '';

  if (path.split('/').length > 2) {
    seoTitle =
      path
        .split('/')[1]
        .split('')[0]
        .toUpperCase() + path.split('/')[1].substr(1);
  }

  return (
    <SnackbarProvider>
      <UserRoleContext.Provider value={[userType, setUserType, recruiterAgencyId, recruiterAgency, adminID, recruiterPermission, isOnBoarded, agencyDetails]}>
        <ConfirmModalContext.Provider value={[modal, setModal]}>
          <GlobalFilterContext.Provider value={{ globalFilter, setGlobalFilter }}>
            <HasJobsOrCandidatesContext.Provider value={[hasJobsOrCandidates, setHasJobOrCandidates]}>
              <ApolloContext.Provider value={{ apolloClient }}>
                <ConfirmModal>
                  <div id="page-container" className={`flex flex-col h-screen items-center font-main bg-lightgray ${fade ? 'opacity-100' : 'opacity-0'}`}>
                    <SEO title={seoTitle} />
                    <Header path={path} currentUser={currentUser} profileModalState={profileModalState} userRoleState={[userType, setUserType]} />
                    <main ref={scrollContainerRef} style={{ minHeight: 'calc(100vh - 80px)', marginTop: 80 }} className="container font-main relative px-md lg:px-0 flex flex-col">
                      {currentUser && children}
                    </main>
                  </div>
                </ConfirmModal>
              </ApolloContext.Provider>
            </HasJobsOrCandidatesContext.Provider>
          </GlobalFilterContext.Provider>
        </ConfirmModalContext.Provider>
      </UserRoleContext.Provider>
    </SnackbarProvider>
  );
}

const gatewayPaths = ['/login', '/new-account', '/new-company-account', '/reset-password', '/cpcjadmin320'];

function ApolloWrapper({ children, currentUser, initializingFirebase, location, navigate, isProfileModalVisible, toggleProfileModal, apolloClient }) {
  if (!initializingFirebase) {
    if (currentUser) {
      if (gatewayPaths.includes(location.pathname)) {
        navigate('/dashboard/');
        return <PageLoading />;
      } else {
        return (
          <AppContainer path={location.pathname} currentUser={currentUser} profileModalState={[isProfileModalVisible, toggleProfileModal]} apolloClient={apolloClient}>
            {children}
          </AppContainer>
        );
      }
    } else if (location.pathname === '/create-account') {
      if (location.state) {
        const { email, password } = location.state;
        return <CreateAccount credentials={{ email, password }} />;
      } else {
        navigate('/login');
        return null;
      }
    } else if (!gatewayPaths.includes(location.pathname)) {
      navigate('/login');
      return <PageLoading />;
    } else {
      return <Gateway path={location.pathname} />;
    }
  } else {
    return <PageLoading />;
  }
}

export default function Layout({ children, location }) {
  const [isProfileModalVisible, toggleProfileModal] = useState(false);
  const { initializingFirebase, currentUser, token } = useFirebase();
  const client = useApollo(token);
  const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PK);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//js.hs-scripts.com/8833398.js';
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return location.pathname === '/candidates/payment/salary-insights' ? <CpPayment /> : location.pathname === '/success' ? <PaymentSuccess /> : client ?
    (
      <Elements stripe={stripePromise}>

        <ApolloProvider client={client}>
          <ApolloWrapper
            apolloClient={client}
            children={children}
            location={location}
            currentUser={currentUser}
            initializingFirebase={initializingFirebase}
            navigate={navigate}
            isProfileModalVisible={isProfileModalVisible}
            toggleProfileModal={toggleProfileModal}
          />
        </ApolloProvider>
      </Elements>
    ) : (
      <PageLoading />
    );
}
