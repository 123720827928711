import { navigate } from '@reach/router';
import React, { useEffect, useRef, useState } from 'react';

export default function ZeroCreditModal ({ state, modalTitle = '', modalBody = '', isFreeAccount = false, subscriptionType = '' }) {
  const [modal, setModal] = state;
  const [, setFadeDelay] = useState(false);
  const modalRef = useRef();

  useEffect(() => {
    if (modal.open) {
      setFadeDelay(true);
    } else {
      setTimeout(() => {
        setFadeDelay(false);
      }, 300);
    }
  }, [modal]);

  return (
    <React.Fragment>
      <div
        onClick={() => setModal({ ...modal, open: true, reset: true })}
        className={`fixed inset-0 ${modal.open ? 'flex' : 'hidden'} items-center justify-center font-main`}
        style={{ backgroundColor: 'rgba(34, 46, 66, 0.9)', zIndex: 20000 }}
        data-backdrop="static"
        data-keyboard="false"
      >
        <div ref={modalRef} onClick={(e) => e.stopPropagation()} className="bg-white px-xl py-md rounded shadow flex flex-col" style={{ minHeight: 200, maxWidth: subscriptionType === 'PAID' ? 650 : 550 }}>
          <div className="text-darkblue font-medium border-b flex justify-between pb-sm">
            { modalTitle || "Lack of credits" }
            <div onClick={() => setModal({ open: false })} className="text-darkgray hover:text-darkblue cursor-pointer">
              x
            </div>
          </div>
          <div className="text-darkblue text-sm text-center py-lg">{ modalBody || "You have zero credits available. To unlock this profile, please purchase more credits on the “Credits” tab."}</div>
          <div className="flex flex-1 justify-between items-end" style={{ marginTop: 40, marginBottom: 20 }}>
            { subscriptionType === 'PAID' ? 
            <div className='flex items-center flex-row justify-center w-full'>
              <button
              onClick={() => {setModal({ ...modal, open: false, reset: true }); navigate('/checkout')} }
              className="rounded bg-red ml-md font-medium  text-sm text-white text"
              style={{ width: 180, height: 55, fontSize: 14 }}
            >
              Buy Credits
            </button>
            <button
              onClick={() => setModal({ ...modal, open: false, reset: true })}
              className="rounded bg-gray-500 ml-md font-medium  text-sm text-white text"
              style={{ width: 180, height: 55, fontSize: 14 }}
            >
              Cancel
            </button>
            </div> : <button
              onClick={() => setModal({ ...modal, open: false, reset: true })}
              className="rounded bg-red ml-md font-medium  text-sm text-white text"
              style={{ width: 180, height: 55, fontSize: 14, boxShadow: '0 14px 10px 0 rgba(255, 0, 0, 0.18)', marginLeft: 140 }}
            >
              { isFreeAccount ? 'Ok' : 'Okay'}
            </button>}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
