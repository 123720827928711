import React, { useEffect } from 'react';
import styled from 'styled-components';
import FieldTitle from './FieldTitle';

const Input = styled.input`
  ::placeholder {
    text-transform: capitalize;
  }
`;

export default function TextInput({ update, subject=true, value, placeholder, error, optional, disabled, width, type, textRef, newResumeValue }) {
  useEffect(() => {
    if (newResumeValue) {
      update(newResumeValue);
    }
  }, [newResumeValue]);
  const excludedPlaceholder = ['Search Attributes']
  return (
    <div className="relative w-full">
      {placeholder && subject && <FieldTitle title={`${placeholder}`} />}
      <Input
        ref={textRef}
        type={type ? type : 'text'}
        autoComplete="asdkfjalsdf"
        disabled={disabled ? 'disabled' : ''}
        onChange={(e) => {
          if (update) {
            update(e.target.value);
          }
        }}
        value={value?.toString() || ''}
        placeholder={placeholder}
        className={`${disabled ? 'bg-lightgray' : 'bg-white'} text-input w-full rounded border relative font-main placeholder-primary text-sm px-md`}
        style={{
          height: 55,
          marginTop: 20,
          borderColor: error && !disabled ? '#E31C25' : '#c4cad3',
        }}
      />
      <div className="absolute font-medium" style={{ fontSize: 12, right: 20, top: 23, color: '#c4cad3' }}>
        {optional && 'OPTIONAL'}
      </div>
    </div>
  );
}
