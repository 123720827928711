import { useQuery, useSubscription } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import firebase from 'firebase/app';
import React, { useContext, useEffect, useState } from 'react';
import UserRoleContext from '../../context/UserRole';
import NewCompanyForm from './NewCompanyForm';
import SelectDropdown from './SelectDropdown';
// , where: { adminID: { _eq: $adminID } }
const ALL_COMPANIES_QUERY = gql`
 subscription getCompanies($adminID: String) {
    companies(order_by: { name: asc }) {
      id
      logoURL
      name
    }
  }
`;

export default function NewCompanyField({ update, error, value }) {
  const [isNewCompany, toggleIsNewCompany] = useState(false);

  const [userRole] = useContext(UserRoleContext);
  const isAdmin = userRole === 'admin';

  const { data } = useSubscription(ALL_COMPANIES_QUERY);
  //  {
    // variables: { adminID: !isAdmin ? firebase.auth().currentUser && firebase.auth().currentUser.uid : null },
  // });
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState(value);

  useEffect(() => {
    update(company);
  }, [company]);

  useEffect(() => {
    if (data) {
      setCompanies(data.companies);
    }
  }, [data]);

  return (
    <>
      {isNewCompany ? (
        <NewCompanyForm showErrors={error} update={setCompany} cancel={() => toggleIsNewCompany(false)} />
      ) : (
        <SelectDropdown value={value && value.name} error={error} action={(e) => toggleIsNewCompany(!isNewCompany)} update={setCompany} list={companies} placeholder="Company" />
      )}
      {error && !isNewCompany && <div className="text-xs text-red font-medium mt-sm text-center">{error}</div>}
    </>
  );
}
