import firebase from 'firebase/app';
import { navigate } from 'gatsby';
import React, { useContext, useState } from 'react';
import * as Yup from 'yup';
import { CompanyField, IndustrySelect, PhoneInput, PhotoUpload, TextInput } from '../fields';
import Form from '../Form';
import { UserRoleContext } from '../../context';

export default function AccountDetailsForm({ action, globalFormData, isEdit }) {
  const [newAccount] = globalFormData;
  const roles = useContext(UserRoleContext);
  let userRole = '';
  if (roles?.length) {
    userRole = roles[0]
  }
  const [errors, setErrors] = useState({});
  const passwordErrorMessage = 'Password must be at least 8 characters, have one capital letter, and one special character';
  const adminFields = {
    email: {
      placeholder: 'email',
      type: 'email',
      component: TextInput,
      value: newAccount ? newAccount.email : '',
      validation: Yup.string()
        .email('Invalid email')
        .required('No email provided'),
    },
    password: {
      placeholder: 'password',
      type: 'password',
      component: TextInput,
      value: newAccount ? newAccount.password : '',
      validation: Yup.string()
        .min(8, passwordErrorMessage)
        .matches(/(?=.*[A-Z])/, passwordErrorMessage)
        .matches(/\W|_/, passwordErrorMessage)
        .required(passwordErrorMessage),
    },
    confirm_password: {
      placeholder: 'confirm password',
      type: 'password',
      component: TextInput,
      value: newAccount ? newAccount.password : '',
      validation: Yup.string()
        .min(8, passwordErrorMessage)
        .matches(/(?=.*[A-Z])/, passwordErrorMessage)
        .matches(/\W|_/, passwordErrorMessage)
        .required(passwordErrorMessage)
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    },
    profilePhoto: {
      placeholder: 'Profile Photo',
      component: PhotoUpload,
      value: newAccount ? newAccount.profilePhoto : '',
    },
    name: {
      component: TextInput,
      value: newAccount ? newAccount.name : '',
      validation: Yup.string().required("Please enter the recruiter's name"),
    },
    title: {
      component: TextInput,
      value: newAccount ? newAccount.title : '',
      validation: Yup.string().required("Please enter the recruiter's title"),
    },
    company: {
      component: CompanyField,
      value: newAccount ? newAccount.company : null,
    },
    industry: {
      value: newAccount ? newAccount.industry : [],
      component: IndustrySelect,
      validation: Yup.mixed().test({
        name: 'Industries',
        message: 'Please select at least one industry',
        test: (value) => (value && value.length === 0 ? false : true),
      }),
    },
    // position: {
    //   component: TextInput,
    //   value: newAccount ? newAccount.position : "",
    //   validation: Yup.string().required(
    //     "Please enter the recruiter's position"
    //   ),
    // },
    phone: {
      component: PhoneInput,
      value: newAccount ? newAccount.phone : '',
    },
  };

  const recruiterFields = {
    profilePhoto: {
      placeholder: 'Profile Photo',
      component: PhotoUpload,
      value: newAccount ? newAccount.profilePhoto : '',
      validation: Yup.mixed().test({
        name: 'Profile Photo',
        message: 'Please upload a profile photo',
        test: (value) => (value ? true : false),
      }),
    },
    name: {
      component: TextInput,
      value: newAccount ? newAccount.name : '',
      validation: Yup.string().required('Please enter your name'),
    },
    title: {
      component: TextInput,
      value: newAccount ? newAccount.title : '',
      validation: Yup.string().required('Please enter your title'),
    },
    company: {
      component: CompanyField,
      value: newAccount ? newAccount.company : null,
    },
    industry: {
      value: newAccount ? newAccount.industries : [],
      component: IndustrySelect,
      validation: Yup.mixed().test({
        name: 'Industries',
        message: 'Please select at least one industry',
        test: (value) => (value && value.length === 0 ? false : true),
      }),
    },
    // position: {
    //   component: TextInput,
    //   value: newAccount ? newAccount.position : "",
    //   validation: Yup.string().required("Please enter your position"),
    // },
    phone: {
      component: PhoneInput,
      value: newAccount ? newAccount.phone : '',
      validation: Yup.mixed().test({
        name: 'Phone number',
        message: 'Please enter your phone number',
        test: (value) => {
          return value && value.length === 14;
        },
      }),
    },
  };

  const agencyFields = {
    profilePhoto: {
      placeholder: 'Profile Photo',
      component: PhotoUpload,
      value: newAccount ? newAccount.profilePhoto : '',
      validation: Yup.mixed().test({
        name: 'Profile Photo',
        message: 'Please upload a profile photo',
        test: (value) => (value ? true : false),
      }),
    },
    name: {
      component: TextInput,
      value: newAccount ? newAccount.name : '',
      validation: Yup.string().required('Please enter your name'),
    },
    title: {
      component: TextInput,
      value: newAccount ? newAccount.title : '',
      validation: Yup.string().required('Please enter your title'),
    },
    // company: {
    //   component: CompanyField,
    //   value: newAccount ? newAccount.company : null,
    // },
    industry: {
      value: newAccount ? newAccount.industries : [],
      component: IndustrySelect,
      validation: Yup.mixed().test({
        name: 'Industries',
        message: 'Please select at least one industry',
        test: (value) => (value && value.length === 0 ? false : true),
      }),
    },
    // position: {
    //   component: TextInput,
    //   value: newAccount ? newAccount.position : "",
    //   validation: Yup.string().required("Please enter your position"),
    // },
    phone: {
      component: PhoneInput,
      value: newAccount ? newAccount.phone : '',
      validation: Yup.mixed().test({
        name: 'Phone number',
        message: 'Please enter your phone number',
        test: (value) => {
          return value && value.length === 14;
        },
      }),
    },
  };

  function onSubmit(values) {
    if (firebase.auth().currentUser) {
      firebase
        .auth()
        .fetchSignInMethodsForEmail(values.email)
        .then((signInMethods) => {
          if (signInMethods.length === 0) {
            // setNewAccount(values);
            action(values);
            // goForward();
          } else {
            setErrors({
              email: 'An account with that email already exists. Please choose another.',
            });
          }
        })
        .catch();
    } else {
      action(values);
      // setNewAccount(values);
      // goForward();
    }
  }

  async function accountCheck(fields) {
    // console.log(`This is in accountCheck func`);

    if (!isEdit) {
      firebase
        .auth()
        .fetchSignInMethodsForEmail(fields.email)
        .then((signInMethods) => {
          if (signInMethods.length === 0) {
            action(fields);
            // setNewAccount(fields);
            // goForward();
          } else {
            setErrors({
              ...errors,
              email: 'An account with that email already exists',
            });
          }
        })
        .catch();
    } else {
      action(fields);
      // setNewAccount({ ...newAccount, ...fields });
      // goForward();
    }
  }

  return (
    <div className="AccountDetailsForm-js py-lg w-full h-full relative container flex flex-col items-center">
      <div className="font-main text-darkblue" style={{ fontWeight: 500, fontSize: 21, paddingBottom: 20 }}>
        {isEdit ? 'Edit Recruiter' : 'Create Recruiter'}
      </div>
      <div className="w-full bg-white rounded shadow flex-1 flex flex-col" style={{ paddingRight: 30, paddingLeft: 30 }}>
        {/* <React.Fragment > */}
        <Form
          fields={firebase.auth().currentUser && !isEdit ? adminFields : userRole === 'agency' ? agencyFields : recruiterFields}
          onSubmit={firebase.auth().currentUser ? accountCheck : onSubmit}
          customErrors={errors}
          setCustomErrors={setErrors}
        >
          <div className="flex w-full mt-lg">
            <button
              onClick={() => (isEdit ? navigate(`/recruiters/${newAccount.id}/`, { state: { recruiter: newAccount } }) : navigate(`/dashboard/recruiters`))}
              type="button"
              // onClick={goBack}
              className="flex-1 p-md mr-sm cursor-pointer bg-lightgray font-main text-darkgray rounded text-sm"
            >
              Cancel
            </button>
            {/* <button
            type="submit"
            style={{ boxShadow: "0 14px 10px 0 rgba(255, 0, 0, 0.18)" }}
            className="flex-1 p-md ml-sm cursor-pointer bg-red font-main text-white rounded text-sm">
            Continue
          </button> */}
            <button
              type="submit"
              className="flex-1 p-md ml-sm cursor-pointer bg-red font-main text-white rounded text-sm"
            >
              Save & Continue
            </button>
          </div>
        </Form>
        {/* </React.Fragment> */}
      </div>
    </div >
  );
}
