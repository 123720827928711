import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import firebase from 'firebase/app';
import React, { useContext, useEffect, useState } from 'react';
import UserRoleContext from '../../context/UserRole';
import Checkbox from './Checkbox';
import { CompensationList } from './CompensationList';
import FieldTitle from './FieldTitle';
import IndustryDropdown from './IndustryDropdown';
import JobAttributes from './JobAttributes';
import JobExperience from './JobExperience';
import LocationField from './LocationField';
import CompanyField from './NewCompanyField'; // change CompanyField with NewCompanyField (16.03.20)
import RichTextArea from './RichTextArea';
import SalaryRange from './SalaryRange';

const GET_COMPANY = gql`
  query getCompany($admin_id: String) {
    company(where: { adminID: { _eq: $admin_id } }) {
      id
      name
    }
  }
`;

export default function FormField ({ name, value, error, update, placeholder }) {
  const [userRole] = useContext(UserRoleContext);
  const isSubscriber = userRole === 'company';

  // set currSubscriber
  const [currSubscriber, setCurrSubscriber] = useState('');

  const { data: companyResponse } = useQuery(GET_COMPANY, {
    variables: { admin_id: firebase.auth().currentUser && firebase.auth().currentUser.uid },
  });

  useEffect(() => {
    if (companyResponse) {
      setCurrSubscriber(companyResponse.company[0]);
    }
  }, [companyResponse]);

  const renderField = (fieldName) => {
    switch (fieldName) {
      case 'company':
        return !isSubscriber ? (
          <CompanyField value={value && value.company} update={update} error={error} />
        ) : (
          <React.Fragment>
            <div className="text-darkblue w-full font-medium " style={{ fontSize: 16, marginTop: 20, fontWeight: 400 }}>
              Company
            </div>
            <input
              value={currSubscriber && currSubscriber.name}
              className={`w-full rounded border placeholder-primary text-sm px-md`}
              style={{ height: 55, marginTop: 20, borderColor: error ? '#E31C25' : '#c4cad3' }}
              disabled
            />
          </React.Fragment>
        );
      case 'description':
        return <RichTextArea value={value} update={update} error={error} placeholder={placeholder} />;
      case 'location':
        return <LocationField value={value} update={update} error={error} />;
      case 'attributes':
        return <JobAttributes value={value} update={update} error={error} />;
      case 'compensation_list':
        return <CompensationList value={value} update={update} error={error} />;
      case 'salary':
        return <SalaryRange value={value} update={update} />;

      case 'industry':
        return <IndustryDropdown value={value} update={update} error={error} />;

      case 'requiredExperience':
        return <>
          <div style={{ paddingLeft: '2px' }}>
            <div className='text-darkblue w-full font-medium flex justify-between' style={{ fontSize: 18, marginTop: 20, fontWeight: 400 }}>
              Required Years of Experience
            </div>
          </div>
          <JobExperience value={value} update={update} error={error} />
        </>

      case 'notes':
        return (
          !Array.isArray(value) && (
            <React.Fragment>
              <FieldTitle title="Internal Notes" optional={true} />
              <textarea
                value={value}
                onChange={(e) => update(e.target.value)}
                placeholder="Internal notes about the job"
                className="w-full rounded border placeholder-primary text-sm px-md"
                style={{ height: 55, paddingTop: 13, borderColor: '#c4cad3', marginTop: 20 }}
              />
            </React.Fragment>
          )
        );
      case 'allowsRemote':
        return <Checkbox label="Allows remote?" value={value} update={update} />;
      case 'hiddenFromCandidates':
        return <Checkbox label="Hide Company from Candidates" value={value} update={update} />;
      default:
        return (
          <input
            onChange={(e) => update(e.target.value)}
            value={value}
            placeholder={placeholder}
            className={`w-full rounded border placeholder-primary text-sm px-md`}
            style={{ height: 55, marginTop: 20, borderColor: error ? '#E31C25' : '#c4cad3' }}
          />
        );
    }
  };

  return (
    <div>
      {renderField(name)}
      {error && name !== 'attributes' && name !== 'company' && <div className="text-xs text-red font-medium mt-sm text-center">{error}</div>}
    </div>
  );
}
