export const DEFAULT_PER_PAGE = 25;

export const QUERY_PARAMS = {
  SEARCH: 'search',
  EXPERIENCE: 'experience',
  LOCATION: 'location',
  ATTRIBUTES: 'attributes',
  SALARY: 'salary',
  LOCATION_PREFERENCES: 'locationPreferences',
  IS_LOGIN: 'c_is_login',
  DATE: 'date',
};

export const FILTER = {
  SEARCH_TERM: 'search_term',
  IS_UNLOCKED: 'is_unlocked',
  MAX_EXPERIENCE: 'max_exp',
  MIN_EXPERIENCE: 'min_exp',
  MIN_SALARY: 'min_salary',
  MAX_SALARY: 'max_salary',
  ATTRIBUTES: 'c_attributes',
  LOCATION_PREFERENCES_RELOCATE: 'location_preference_willing_to_relocate',
  LOCATION_PREFERENCES_REMOTE: 'location_preference_remote',
  LOCATION_LAT: 'g_lat',
  LOCATION_LNG: 'g_long',
  LOCATION_RADIUS: 'g_radius',
  IS_LOGIN: 'c_is_login',
  START_DATE: 'start_date',
  END_DATE: 'end_date',
};

export const QUERY_FILTERS = {
  [FILTER.SEARCH_TERM]: null,
  [FILTER.IS_UNLOCKED]: null,
  [FILTER.MAX_EXPERIENCE]: null,
  [FILTER.MIN_EXPERIENCE]: null,
  [FILTER.MIN_SALARY]: null,
  [FILTER.MAX_SALARY]: null,
  [FILTER.ATTRIBUTES]: null,
  [FILTER.LOCATION_PREFERENCES_RELOCATE]: null,
  [FILTER.LOCATION_PREFERENCES_REMOTE]: null,
  [FILTER.LOCATION_LAT]: null,
  [FILTER.LOCATION_LNG]: null,
  [FILTER.LOCATION_RADIUS]: null,
  [FILTER.IS_LOGIN]: null,
  [FILTER.START_DATE]: null,
  [FILTER.END_DATE]: null,
};

export const USER_ROLES = {
  SUBSCRIBER: 'Subscriber',
  ADMIN: 'Admin',
  RECRUITER: 'Recruiter',
  CANDIDATE: 'Candidate',
  COMPANY_SUBSCRIBER: 'Company',
  AGENCY: 'Agency'
};

// ? Used for @skip
// export const COMMON_QUERY_PARAMS = `${Object.values(FILTER).map((filter) => `$${filter}: Boolean! `)}$perPage: Int, $offset: Int`;

export const EMAIL_TEMPLATES = {
  TO_RECRUITER_WHEN_CANDIDATE_INTERESTED_IN_JOB_POSTED_BY_RECRUITER_APP: 'TO_RECRUITER_WHEN_CANDIDATE_INTERESTED_IN_JOB_POSTED_BY_RECRUITER_APP',
  TO_RECRUITER_WHEN_COMPANY_UNLOCK_CANDIDATE_PROFILE: 'TO_RECRUITER_WHEN_COMPANY_UNLOCK_CANDIDATE_PROFILE',
  TO_SUBSCRIBER_WHEN_JOB_POSTING_SUBMITTED_FOR_APPROVAL_BY_ADMIN: 'TO_SUBSCRIBER_WHEN_JOB_POSTING_SUBMITTED_FOR_APPROVAL_BY_ADMIN',
  TO_CANDIDATE_WHEN_QUEUE_PROFILE_APPROVED_BY_RECRUITER: 'TO_CANDIDATE_WHEN_QUEUE_PROFILE_APPROVED_BY_RECRUITER',
  TO_CANDIDATE_WHEN_PROFILE_UNLOCKED_BY_SUBSCRIBER: 'TO_CANDIDATE_WHEN_PROFILE_UNLOCKED_BY_SUBSCRIBER',
  TO_RECRUITER_WHEN_SUBSCRIBER_INTERESTED_IN_CANDIDATE_PROFILE: 'TO_RECRUITER_WHEN_SUBSCRIBER_INTERESTED_IN_CANDIDATE_PROFILE',
  CANDIDATE_SUPPORT_APP: 'CANDIDATE_SUPPORT_APP',
  TO_RECRUITER_TO_APPROVE_CANDIDATES_PROFILE_CHANGE_APP: 'TO_RECRUITER_TO_APPROVE_CANDIDATES_PROFILE_CHANGE_APP',
  TO_SUBSCRIBER_WHEN_CANDIDATE_APPROVES_INTRODUCTION_REQUEST_APP: 'TO_SUBSCRIBER_WHEN_CANDIDATE_APPROVES_INTRODUCTION_REQUEST_APP',
  TO_CANDIDATE_WHEN_SIGN_UP_FROM_MOBILE_SURVEY_APP: 'TO_CANDIDATE_WHEN_SIGN_UP_FROM_MOBILE_SURVEY_APP',
  JOB_MATCH: 'JOB_MATCH',
  TO_ADMIN_WHEN_SUBSCRIBER_POSTED_NEW_JOB: 'TO_ADMIN_WHEN_SUBSCRIBER_POSTED_NEW_JOB',
  TO_SUBSCRIBER_WHEN_JOB_POSTING_APPROVED_BY_ADMIN: 'TO_SUBSCRIBER_WHEN_JOB_POSTING_APPROVED_BY_ADMIN',
  TO_SUBSCRIBER_WHEN_CANDIDATE_INTERESTED_IN_SUBSCRIBER_JOB_POSTING_APP: 'TO_SUBSCRIBER_WHEN_CANDIDATE_INTERESTED_IN_SUBSCRIBER_JOB_POSTING_APP',
  TO_CANDIDATE_INVITATION_EMAIL_WITH_PROMO_CODE: 'TO_CANDIDATE_INVITATION_EMAIL_WITH_PROMO_CODE',
  TO_CP_ADMIN_WHEN_SUBSCRIBER_HIRE_CANDIDATE: 'TO_CP_ADMIN_WHEN_SUBSCRIBER_HIRE_CANDIDATE',
  TO_RECRUITER_AND_AGENCY_WHEN_SUBSCRIBER_HIRE_CANDIDATE: 'TO_RECRUITER_AND_AGENCY_WHEN_SUBSCRIBER_HIRE_CANDIDATE',
  TO_SUBSCRIBER_WHEN_SUBSCRIBER_HIRE_CANDIDATE: 'TO_SUBSCRIBER_WHEN_SUBSCRIBER_HIRE_CANDIDATE',
  TO_SUBSCRIBER_WHEN_ADMIN_ADD_CREDIT: 'TO_SUBSCRIBER_WHEN_ADMIN_ADD_CREDIT',
  FROM_ADMIN_TO_SUBSCRIBER_WHEN_NEW_ACCOUNT_CREATED: 'FROM_ADMIN_TO_SUBSCRIBER_WHEN_NEW_ACCOUNT_CREATED',
  TO_ADMIN_WHEN_SUBSCRIBER_FREE_CREDIT_ZERO: 'TO_ADMIN_WHEN_SUBSCRIBER_FREE_CREDIT_ZERO',
}

export const NOTIFIACTION_STACK = {
  SINGLE: 'SINGLE',
  GROUPED: 'GROUPED'
};

export const bioLorem = [
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
].join(' ');

export const defaultCompanyLogo =
  'https://firebasestorage.googleapis.com/v0/b/cherry-picker-a3314.appspot.com/o/images%2Fcompanylogos%2Fbriefcase.png?alt=media&token=6b69a0dc-00d6-4a94-b893-3844be607b00';

const EMAIL_IMAGE_LINK =
  'https://firebasestorage.googleapis.com/v0/b/cherry-picker-a3314.appspot.com/o/images%2Fheader-logo.png?alt=media&token=defa4e61-56b0-438b-aaa1-9572fb2f8691';

  /*113
<p style="margin-top:10px;font-size:17px">
    Your invite link is: <a href="##_INVITE_CODE_LINK_##">##_INVITE_CODE_LINK_##</a>
  </p>
  */
export const DEFAULT_EMAIL_INVITE_BODY = `<div style="border: 2px solid red;padding: 20px;">
  <img src=${EMAIL_IMAGE_LINK} style="max-width: 250px;width: 50%;margin:0px" />
  <p style="font-size:17px">
    Hi {{candidate_name}},
  </p>
  <p style="margin-top:10px;font-size:17px">
    I’m CJ Tufano, Founder, and CEO of Cherrypicker - the first invite-only, confidential talent network for top talent like you who are killing it in their current job but are passively keeping their options open.  Top companies leverage our platform to get introduced to great candidates like you and it seems like there is some interest in your background.
  </p>
  <p style="margin-top:10px;font-size:17px">
    So, I would like to personally invite you to join our Cherrypicker mobile platform with your own unique invite code.
  </o>
  <p style="margin-top:10px;font-size:17px">
    Your invite code is: <span style="font-weight:bold;font-size:17px">{{invitation_code}} </span>
  </p>
  
  <p style="margin-top:10px;font-weight:bold;font-size:17px">
    Follow these next few steps below to avoid the waitlist and activate your optimized career DNA profile that has been vetted for success by one of our Talent Representatives.
  </p>
  <p style="font-size:17px">
    1 - Use the invite link above OR download the Cherrypicker App from the App Store (iPhone) or Google Play Store (Android).
  </p>
  <p style="font-size:17px">
    2 - Tap or click "Have an invite code?" and enter your invite code along with the email address you received this message on
  </p>
  <p style="font-size:17px">
    3 - Review your profile that we made for you.  Set your salary requirements, location preferences, and add any additional details that your profile might be missing. We will review any new changes you make.
  </p>
  <p style="font-size:17px">
    4- Once we approve your profile changes, sit back, relax, and Get Cherrypicked!
  </p>
  <p style="margin-top:25px;font-size:17px">
    You will have full control of your passive search. We completely hide your identity so you can confidentially review matched opportunities and company introductions on your terms, based on your preferences.  Your current employer might even try to request an introduction to you!  Don't worry they can't see who you are, that's how you know it's working! ;)  Just simply hit "Not Interested" or "Block Company".
  </p>
  <p style="margin-top:10px;font-size:17px">
    Once you have successfully joined and activated your profile, please respond back to this email to confirm.
  </p>
  <p style="margin-top:10px;font-size:17px">
    My goal is to make sure you are successful on Cherrypicker!  If you have any additional questions, feel free to ask away.  I look forward to hearing from you.
  </p>
  <p style="margin-top:30px;font-size:17px">
    Best,<br /><br />
    <b>CJ Tufano<br />
    Founder/CEO<br />
    Cherrypicker, Inc. | New York<br/>
    <a href="www.getcherrypicker.com">www.getcherrypicker.com</a></b>
  </p>
</div>`;

export const BACKGROUND_SYNC_FREQUENCIES = {
  NONE: 'None',
  DAILY: 'Daily',
  EVERY_12_HOURS: 'Every 12 hours',
  EVERY_6_HOURS: 'Every 6 hours',
  EVERY_3_HOURS: 'Every 3 hours',
};

export const SUBSCRIPTION_TYPES = {
  FREE: 'Free',
  PAID: 'Paid',
};

// Statuses to be used for candidate statuses in queries.
// TODO: Refactor code to support these
export const REQUEST_STATUSES = {
  pending: 'pending',
  request: 'request',
  accepted: 'accepted',
  requested: 'requested',
  approved: 'approved',
  loading: 'loading',
  declined: 'declined',
  interested: 'interested',
  denied: 'denied',
  hired: 'hired'
};
