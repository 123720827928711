import React, { useState, useEffect } from "react";
import AttributesField from "./AttributesField";
import FieldTitle from "./FieldTitle";

export default function JobAttributes({ value, update, error }) {
  const [required, setRequired] = useState(value ? typeof value.required === 'string' ? JSON.parse(value.required) : value.required : []);
  const [optional, setOptional] = useState(value ? typeof value.optional === 'string' ? JSON.parse(value.optional) : value.optional : []);

  useEffect(() => {
    update({
      required: required ? required : [],
      optional: optional ? optional : [],
    });
  }, [required, optional]);

  return (
    <div>
      {/* <FieldTitle title="Optional Attributes" /> */}
      {/* <AttributesField value={optional} update={setOptional} /> */}
      <FieldTitle title="Required Attributes" />
      <AttributesField value={required} update={setRequired} />
      {error && required.length === 0 && (
        <div className="text-xs text-red font-medium mt-sm text-center">
          At least one required attribute is required
        </div>
      )}
    </div>
  );
}
