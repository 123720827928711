import moment from 'moment';
import React, { useEffect, useState } from 'react';

const monthsWith30Days = ['04', '06', '09', '11'];

function DateSelectField({ value, disabled, type, update, current, noDay }) {
  const date = value[`${type}Date`] !== '' && moment(value[`${type}Date`]);
  const [now] = useState(moment());
  const [month, setMonth] = useState(date ? date.format('MM') : undefined);
  const [day, setDay] = useState(date ? date.format('DD') : undefined);
  const [year, setYear] = useState(date ? date.format('YYYY') : undefined);

  useEffect(() => {
    const newDate = moment(`${year || new Date().getFullYear()}-${month || 1}-${day || 1}`);
    if (newDate.isValid()) {
      update({
        ...value,
        [`${type}Date`]: moment(`${year || new Date().getFullYear()}-${month || 1}-${day || 1}`).toDate(),
        current,
      });
    } else {
      update({ ...value, [`${type}Date`]: '', current });
    }
  }, [day, month, year, current]);

  /*  useEffect(() => {
     if(value) {
       const date = value !== "" && moment(value[`${type}Date`])
       setDay(date && date.format("DD"))
       setMonth(date && date.format("MM"))
       setYear(date && date.format("YYYY"))
     }
   }, [value]) */

  return (
    <div className="flex">
      <div className="relative" style={{ marginRight: 10 }}>
        <select
          disabled={disabled}
          value={month}
          onChange={(e) => setMonth(e.target.value)}
          className={`border relative z-10 border-lightgray ${month ? 'text-darkblue' : 'text-darkgray'} text-center`}
          style={{
            WebkitAppearance: 'none',
            backgroundColor: 'rgba(0,0,0,0)',
            fontSize: 14,
            paddingLeft: month ? 17 : 13,
            height: 55,
            width: 55,
            borderColor: 'rgb(196, 202, 211)',
          }}
        >
          <option value={undefined} disabled={true}>
            MM
          </option>
          {Array(12)
            .fill()
            .map((_, i) => (
              <option key={i} value={`${i + 1 < 10 ? 0 : ''}${i + 1}`}>
                {`${i + 1 < 10 ? 0 : ''}${i + 1}`}
              </option>
            ))}
        </select>
        {disabled && (
          <div className="bg-lightgray inset-0 flex items-center justify-center text-darkgray absolute rounded border-darkgray" style={{ zIndex: 20, fontSize: 14 }}>
            MM
          </div>
        )}
      </div>
      {noDay === false && (
        <div className="relative" style={{ marginRight: 10 }}>
          <select
            disabled={disabled}
            value={day}
            onChange={(e) => setDay(e.target.value)}
            className={`border relative z-10 border-lightgray ${day ? 'text-darkblue' : 'text-darkgray'} text-center`}
            style={{
              WebkitAppearance: 'none',
              backgroundColor: 'rgba(0,0,0,0)',
              fontSize: 14,
              height: 55,
              paddingLeft: day ? 17 : 14,
              width: 55,
              borderColor: 'rgb(196, 202, 211)',
            }}
          >
            <option value={undefined} disabled={true}>
              DD
            </option>
            {Array(month === '02' ? 28 : monthsWith30Days.includes(month) ? 30 : 31)
              .fill()
              .map((_, i) => (
                <option key={i}>{`${i + 1 < 10 ? 0 : ''}${i + 1}`}</option>
              ))}
          </select>
          {disabled && (
            <div className="bg-lightgray inset-0 flex items-center justify-center text-darkgray absolute rounded border-darkgray" style={{ zIndex: 20, fontSize: 14 }}>
              DD
            </div>
          )}
        </div>
      )}
      <div className="relative">
        <select
          value={year}
          disabled={disabled}
          onChange={(e) => setYear(e.target.value)}
          className={`border relative z-10 border-lightgray ${year ? 'text-darkblue' : 'text-darkgray'} text-center`}
          style={{
            WebkitAppearance: 'none',
            backgroundColor: 'rgba(0,0,0,0)',
            fontSize: 14,
            paddingLeft: 32,
            height: 55,
            width: 110,
            borderColor: 'rgb(196, 202, 211)',
          }}
        >
          <option value={undefined} disabled={true}>
            YYYY
          </option>
          {Array(50)
            .fill()
            .map((_, i) => {
              const year = now.format('YYYY');
              return <option key={i}>{year - i}</option>;
            })}
        </select>
        {disabled && (
          <div className="bg-lightgray inset-0 flex items-center justify-center text-darkgray absolute rounded border-darkgray" style={{ zIndex: 20, fontSize: 14 }}>
            YYYY
          </div>
        )}
      </div>
    </div>
  );
}

export default function TenureField({ value, update, isEducation }) {
  const [isCurrent, toggleIsCurrent] = useState(value.current);

  return (
    <div className="flex flex-col sm:flex-row w-full justify-between" style={{ marginTop: 10 }}>
      {!isEducation && (
        <div className="relative" style={{ width: 'calc(50% - 10px)' }}>
          <div className="font-medium" style={{ fontSize: 16, marginBottom: 5 }}>
            Start Date
          </div>
          <DateSelectField type="start" current={isCurrent} value={value} update={update} noDay={true} />
        </div>
      )}
      <div className="relative" style={{ width: 'calc(50% - 10px)' }}>
        <div className="font-medium" style={{ fontSize: 16, marginBottom: 5 }}>
          End Date
        </div>
        <DateSelectField type="end" value={value} current={isCurrent} update={update} disabled={isCurrent} noDay={true} />
        <div className="flex items-center">
          <input checked={isCurrent} type="checkbox" className="mr-sm cursor-pointer" onChange={() => toggleIsCurrent(!isCurrent)} />
          <div className="text-sm font-medium text-darkblue relative" style={{ top: 1 }}>
            Present?
          </div>
        </div>
      </div>
    </div>
  );
}
