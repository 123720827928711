import React, { useEffect, useRef, useState } from 'react';
import UploadIcon from '../../images/upload-icon.svg';
import FieldTitle from './FieldTitle';

export default function PhotoUpload({ value, onFileSelect, error }) {
  const uploadRef = useRef();
  const [photo, setPhoto] = useState();
  const [photoURL, setPhotoURL] = useState(value);

  useEffect(() => {
    if (photo) {
      onFileSelect(photo);
      setPhotoURL(URL.createObjectURL(photo));
    }
  }, [photo]);

  return (
    <div className='w-full'>
      <FieldTitle title='Company Logo' />
    <div
      onClick={() => uploadRef.current.click()}
      className={`w-full rounded bg-lightgray flex cursor-pointer font-main text-darkgray text-sm items-center justify-between px-md`}
      style={{
        height: 55,
        // width: 'calc(50% - 20px)',
        marginTop: 20,
        marginRight: 20,
      }}
    >
      <div className="flex-1">{photoURL ? <img src={photoURL} style={{ maxHeight: 40, maxWidth: '100%', margin: 0 }} /> : 'Company Logo'}</div>
      <img src={UploadIcon} style={{ height: 22, marginBottom: 0, marginLeft: 20 }} />
      <input onChange={({ target: { files } }) => setPhoto(files[0])} ref={uploadRef} accept=".png,.jpg,.jpeg" type="file" style={{ display: 'none' }} />
    </div>
    </div>
  );
}
