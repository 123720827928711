import firebase from 'firebase/app';
import 'firebase/auth';
import { Link } from 'gatsby';
import { request } from 'graphql-request';
import React, { useState } from 'react';
import Loader from 'react-loader-spinner';
import styled from 'styled-components';
import * as Yup from 'yup';
import { HTTP_HASURA_API_URL } from '../../config';
import LandingInput from '../fields/LandingInput';
import Form from '../Form';
import { ToastContainer, toast } from 'material-react-toastify';

const GatewayTitle = styled.h1`
  font-size: 2.25rem;
  @media only screen and (max-width: 640px) {
    font-size: 1.5rem;
    position: relative;
    top: 15px;
  }
`;

const RECRUITER_CHECK = `
  query recruiterCheck($email: String) {
    recruiter(where: { email: { _eq: $email } }) {
      email
    }
    company(where: { email: { _eq: $email } }) {
      email
      is_agency
    }
  }
`;

export default function LoginForm({ isActive }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const logIn = async (credentials) => {
    try {
      await firebase.auth().signInWithEmailAndPassword(credentials.email, credentials.password);
    } catch ({ code }) {
      switch (code) {
        case 'auth/invalid-email':
          // setError({
          //   email: 'Please enter a valid email address',
          // });
          toast.error('Please enter a valid email address');
          break;
        case 'auth/user-not-found':
          // setError({
          //   email: 'A user with that email was not found.',
          // });
          toast.error('A user with that email was not found.');
          break;
        case 'auth/user-disabled':
          // setError({
          //   email: 'Account disabled, Please contact support@getCherryPicker.com.',
          // });
          toast.error('Account disabled, Please contact support@getCherryPicker.com.');

          break;
        case 'auth/wrong-password':
          // setError({
          //   password: 'The password provided was incorrect',
          // });
          toast.error('The password provided was incorrect');

          break;
        case 'auth/too-many-requests':
          // setError({
          //   password: 'Too many failed log-in attempts. Please reset your password or try again later.',
          // });
          toast.error('Too many failed log-in attempts. Please reset your password or try again later.');

          break;
        default:
          break;
      }
      setLoading(false);
    }
  };

  const fields = {
    email: {
      type: 'email',
      component: LandingInput,
      value: '',
      placeholder: 'Email',
      fieldName: 'Email Address',
      validation: Yup.string()
        .email('Invalid Email')
        .required('No email provided'),
    },
    password: {
      fieldName: 'Password',
      placeholder: 'Password',
      type: 'password',
      component: LandingInput,
      value: '',
      validation: Yup.mixed().required('Invalid password'),
    },
  };

  const recruiterCheck = (credentials) => {
    setLoading(true);
    request(HTTP_HASURA_API_URL, RECRUITER_CHECK, {
      email: credentials.email.toLowerCase(),
    })
      .then((recruiterResponse) => {
        if (recruiterResponse.recruiter.length > 0 || recruiterResponse.company.length > 0 || credentials.email.toLowerCase() === 'admin@getcherrypicker.com') {
          logIn(credentials);
        } else {
          // console.log(recruiterResponse);
          // setError({ email: 'No account with that email exists' });
          toast.error('No account with that email exists');
          setLoading(false);
        }
      })
      .catch(console.log);
  };

  return (
    <div
      className="flex flex-col items-center mx-lg lg:mx-0"
      style={{
        opacity: isActive ? 1 : 0,
        transition: 'opacity 0.3s ease-in-out',
        boxSizing: 'border-box',
        padding: '62px 13px 0px 32px'
      }}
      id='login-form'
    >
      <img style={{ width: 200, margin: 0 }} src='https://cherrypicker.ai/logos/auth_logo.png' />
      <GatewayTitle style={{ fontSize: '1.7rem', fontWeight: 400 }} className="font-main text-black text-center pt-3 pb-2">
        Log In</GatewayTitle>
      <Form className="flex flex-col items-center" fields={fields} onSubmit={recruiterCheck} customErrors={error} setCustomErrors={setError}>
        <span className='text-black font-medium'> By logging in you agree to the </span>
        <span className='text-black'>
          <a className='font-semibold' href="https://www.getcherrypicker.com/terms" target="_black" style={{ color: '#e44027' }}>
          Privacy Policy
        </a>{' '}
        and{' '}
          <a className='font-semibold' href="https://www.getcherrypicker.com/privacypolicy" target="_black" style={{ color: '#e44027' }}>
          Terms of Service
        </a>
        </span>

        <div className="flex w-full justify-center">
          <button type="submit" className="bg-red text-white flex justify-center font-main p-md rounded mt-lg cursor-pointer w-full" style={{ height: 56 }}>
            {loading ? <Loader type="TailSpin" color="#FFFFFF" height={20} width={20} /> : 'Log in'}
          </button>
        </div>
        {/* <div className="mt-md flex justify-center font-main text-white">
          Don't have an account?
          <Link to="/new-account" className="ml-sm font-bold cursor-pointer">
            Create One
          </Link>
        </div> */}
      </Form>
      <div className="flex w-full justify-center">
        <Link to="/reset-password" className="font-main text-red font-semibold text-base cursor-pointer">
          Forgot Password?
        </Link>
      </div>
    </div>
  );
}
